<template>
  <v-card
    class="mx-auto"
    max-width="auto"
  >
    <v-toolbar max-height="75px">
        <v-toolbar-title>Frequently Asked Questions (FAQ)</v-toolbar-title>
      <v-spacer></v-spacer>
        <v-btn 
            text
            color="red"
            small
            >
            <DashboardAddFaq/>
        </v-btn>
    </v-toolbar>
  
    <v-list two-line>
      <v-list-item-group>
        
        <template v-for="(item, index) in faqList">
          <v-list-item :key="item.title">

              <v-list-item-content>
                <!-- <v-list-item-title>{{index+1}}. {{item.title}}</v-list-item-title> -->
                <v-list-item-subtitle class="text--primary">{{index+1}}. {{item.title}}</v-list-item-subtitle>
                <v-list-item-subtitle v-text="item.description"></v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action>
                <v-list-item-action-text v-text="item.action"></v-list-item-action-text>
    
                <v-row class="mt-1">
                      <v-col>
                           <v-btn 
                              text
                              color="red"
                              small
                              >
                              <DashboardEditFaq :item="item"> </DashboardEditFaq>
                          </v-btn>
                      </v-col>
                      <v-col>
                        <v-btn 
                            text
                            color="red"
                            small
                            @click="deleteFaq(item.id)"
                            >
                            <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </v-col>
                  </v-row>
                
                <!-- <v-icon @click="deleteBranch(item.id)" color="error" y>mdi-delete</v-icon> -->
                
              </v-list-item-action>

          </v-list-item>
        </template>
      </v-list-item-group>
    </v-list>

    <v-dialog
        v-model="deleteDialog"
        persistent
        max-width="290"
        >
        <v-card>
            <v-card-title>
                Confirmation
            </v-card-title>
            <v-card-text>
                <p style="font-size: 15px;">Are you sure you want to delete?</p>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    text
                    @click="cancelDialog"
                    >
                    Cancel
                </v-btn>
                <v-btn
                    text
                    @click="confirmDelete"
                    >
                    Confirm
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

  </v-card>
</template>


<script>
import DashboardEditFaq from '../components/modules/salesProspecting/DashboardEditFaq.vue';
import DashboardAddFaq from '../components/modules/salesProspecting/DashboardAddFaq.vue';

export default{
    data () {
        return {
            faqList: null,
            acronymList: null,
            statusList: null,
            deleteDialog: false,
            deleteId: ''
        }
    },
    components:{
      DashboardEditFaq: DashboardEditFaq,
      DashboardAddFaq: DashboardAddFaq,
    },
    async mounted () {
      const res = await this.$store.dispatch('salesProspecting/doGetDashboardData')
      this.assigndata(res.data.result)
    },
	methods:{
		assigndata(data){
			this.faqList = data.dashboard_faq
		},
    deleteFaq(id){
      this.deleteDialog = true
      this.deleteId = id
    },
    cancelDialog(){
      this.deleteId = ''
      this.deleteDialog = false
    },
    async confirmDelete(){
      const id = this.deleteId
      const res = await this.$store.dispatch('salesProspecting/doDelete', id)
        if (res.status === 200){
            this.$store.dispatch('app/setSnackBar',{'status': true,'message':'Deleted Successfully!','messagetype':'success'});
            this.deleteDialog = false
            location.reload();
        }
    }
	}
}
</script>
